import { Reducer } from 'redux'
import { PickerTypes, PickersState } from './types'

const INITIAL_STATE: PickersState = {
  progress: 0,
  selected: {
    Parede: 'a',
    Piso: '1',
  },
}

export function getSelectedUrl(
  selected: string | Record<string, string>
): string {
  const key =
    typeof selected === 'string'
      ? selected
      : Object.values(selected).sort().reverse().join('')
  return `https://real2u-public-assets.s3.amazonaws.com/images/live-scenes/md/${key}.png`
}

const reducer: Reducer<PickersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PickerTypes.UPDATE_SELECTION: {
      const { update } = action.payload
      return { ...state, selected: { ...state.selected, ...update } }
    }
    case PickerTypes.GET_SELECTIONS_SUCCESS: {
      const { progress } = action.payload
      return { ...state, progress }
    }
    default:
      return state
  }
}

export default reducer
