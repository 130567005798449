import styled from 'styled-components'

export const Container = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:link,
  &:visited,
  &:active,
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  small {
    margin-bottom: 10px;
  }

  img {
    width: 70px;
    height: 36px;
    object-fit: contain;
  }
`
