import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 100%;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 961px) {
    display: none;
  }

  ul {
    padding: 0;
    list-style-type: none;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    grid-gap: 20px;
  }

  hr {
    margin-bottom: 20px;
  }
`

interface Props {
  selected: boolean
}
export const Selection = styled.li<Props>`
  padding-top: 5px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  height: 101px;

  ${(props) =>
    props.selected &&
    css`
      background-color: #4d4d4d;
      text-color: white;
      width: 85px;
    `}

  button {
    padding: 0;
    border: none;
    background: none;
    &:focus {
      outline: 0;
    }

    img {
      width: 75px;
      height: 75px;
      object-fit: contain;
      border-radius: 5px;
      border: solid 1px rgba(0, 0, 0, 0.1);
    }

    h3 {
      margin-top: 5px;

      color: ${({ selected }: Props) => (selected ? '#ffffff' : '#000000')};
    }
  }
`
