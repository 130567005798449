import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { getSelectedUrl } from '../../store/modules/pickers'
import { Container } from './styles'

const Scene: React.FC = () => {
  const { selected } = useSelector((state: ApplicationState) => state.pickers)
  return (
    <Container>
      <img alt="scene" src={getSelectedUrl(selected)} />
    </Container>
  )
}

export default Scene
