import React from 'react'
import { Line } from 'rc-progress'
import { Container } from './styles'
import PoweredBy from '../PoweredBy'

interface Props {
  percent: number
}
const Loading: React.FC<Props> = ({ percent }: Props) => {
  return (
    <Container>
      <h1>Live Scenes</h1>
      <PoweredBy />
      <Line percent={percent} />
    </Container>
  )
}

export default Loading
