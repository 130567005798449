import React from 'react'
import { Container } from './styles'
import logo from './logo.jpg'

const PoweredBy: React.FC = () => {
  return (
    <Container href="https://r2u.io">
      <small>powered by</small>
      <img alt="r2u" src={logo} />
    </Container>
  )
}

export default PoweredBy
