import { all, call, put, takeLatest } from 'redux-saga/effects'
import { PickerTypes } from './types'
import { getSelectionsSuccess } from './action'
import { getSelectedUrl } from '.'
import pickers from './pickers.json'

export function* getSelections(): Iterator<unknown> {
  const combinations = pickers.map((e) => e.selections.map((s) => s.id))
  function cartesianProduct<T>(...allEntries: T[][]): T[][] {
    return allEntries.reduce<T[][]>(
      (results, entries) =>
        results
          .map((result) => entries.map((entry) => result.concat([entry])))
          .reduce((subResults, result) => subResults.concat(result), []),
      [[]]
    )
  }
  const permutations = cartesianProduct(...combinations)
  const get = async (permutation: string[]) => {
    return new Promise<void>((resolve, reject) => {
      const imageUrl = getSelectedUrl(permutation.join(''))
      const img = new Image()
      img.src = imageUrl
      img.onload = () => {
        // put(getSelectionsSuccess(i / permutations.length))
        resolve()
      }
      img.onerror = () => reject()
    })
  }

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < permutations.length; index++) {
    yield call(get, permutations[index])
    yield put(getSelectionsSuccess((index + 1) / permutations.length))
  }

  yield put(getSelectionsSuccess(1))
}

export default all([
  takeLatest(PickerTypes.GET_SELECTIONS_REQUEST, getSelections),
])
