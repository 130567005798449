import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  h1,h2,h3,span,small {
    & {
      font-family: proxima-nova, sans-serif;
      color: #222222;
    }
  }
  h1 {
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
  }
  h3 {
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
  }
  small {
    font-size: 9px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
  }
  body {
    background-color: #ffff;
  }
  button {
    cursor: pointer
  }
`

export default GlobalStyle
