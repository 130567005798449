import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Center, Divider } from './styles'
import Scene from '../Scene'
import ControlPanel from '../ControlPanel'
import Loading from '../Loading'
import OpenDesktop from '../OpenDesktop'
import { getSelectionsRequest } from '../../store/modules/pickers/action'
import { ApplicationState } from '../../store'

const LiveScenes: React.FC = () => {
  const { progress } = useSelector((state: ApplicationState) => state.pickers)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSelectionsRequest())
  }, [dispatch])

  return (
    <Center>
      <Container>
        <OpenDesktop />
        {progress < 1 ? (
          <Loading percent={100 * progress} />
        ) : (
          <>
            <ControlPanel />
            <Divider />
            <Scene />
          </>
        )}
      </Container>
    </Center>
  )
}

export default LiveScenes
