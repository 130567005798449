import styled from 'styled-components'

export const Container = styled.div`
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;

  @media (max-width: 961px) {
    display: none;
  }

  a {
    margin-bottom: 10px;
  }
`
