/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions'
import { PickerTypes } from './types'

export const updateSelection = (update: Record<string, string>) =>
  action(PickerTypes.UPDATE_SELECTION, { update })
export const getSelectionsRequest = () =>
  action(PickerTypes.GET_SELECTIONS_REQUEST)
export const getSelectionsSuccess = (progress: number) =>
  action(PickerTypes.GET_SELECTIONS_SUCCESS, { progress })
