export enum PickerTypes {
  'UPDATE_SELECTION' = '@pickers/UPDATE_SELECTION',
  'GET_SELECTIONS_REQUEST' = '@pickers/GET_SELECTIONS_REQUEST',
  'GET_SELECTIONS_SUCCESS' = '@pickers/GET_SELECTIONS_SUCCESS',
}

export interface PickersState {
  selected: Record<string, string>
  progress: number
}

interface Selection {
  id: string
  name: string
  thumbnailUrl: string
}

export interface Picker {
  name: string
  selections: Selection[]
}
