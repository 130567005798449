import {
  applyMiddleware,
  compose,
  createStore,
  Store,
  StoreEnhancer,
} from 'redux'

import createSagaMiddleware from 'redux-saga'
import rootSaga from './modules/rootSaga'

import rootReducer from './modules/rootReducer'
import { PickersState } from './modules/pickers/types'

export interface ApplicationState {
  pickers: PickersState
}

declare global {
  interface Console {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tron: any
  }
}

const sagaMiddleware = createSagaMiddleware()

const enhancer: StoreEnhancer =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware)

const store: Store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export { store }
