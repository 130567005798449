import React from 'react'
import { Provider } from 'react-redux'
import LiveScenes from './components/LiveScenes'
import GlobalStyle from './styles'

import { store } from './store'

const App: React.FC = () => (
  <Provider store={store}>
    <GlobalStyle />
    <LiveScenes />
  </Provider>
)

export default App
