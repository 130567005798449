import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: auto;
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100vw - 16px);
  height: calc(100vh - 16px);
`

export const Divider = styled.div`
  width: 1px;
  background-color: #b3b3b3;
  height: 100%;
  float: left;
  margin-right: 50px;

  @media (max-width: 961px) {
    display: none;
  }
`
