import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelection } from '../../store/modules/pickers/action'
import pickers from '../../store/modules/pickers/pickers.json'
import PoweredBy from '../PoweredBy'
import { Container, Selection } from './styles'
import { ApplicationState } from '../../store'
import { Picker } from '../../store/modules/pickers/types'

const ControlPanel: React.FC = () => {
  const { selected } = useSelector((state: ApplicationState) => state.pickers)
  const dispatch = useDispatch()
  return (
    <Container>
      <div>
        {(pickers as Picker[]).map((picker, pi) => (
          <div key={picker.name}>
            <h2>{picker.name}</h2>
            <ul>
              {picker.selections.map((selection) => (
                <Selection
                  key={selection.id}
                  selected={selected[picker.name] === selection.id}
                >
                  <button
                    type="button"
                    onClick={() =>
                      dispatch(updateSelection({ [picker.name]: selection.id }))
                    }
                  >
                    <img alt={selection.name} src={selection.thumbnailUrl} />
                    <h3>{selection.name}</h3>
                  </button>
                </Selection>
              ))}
            </ul>
            {pi === pickers.length - 1 ? null : <hr />}
          </div>
        ))}
      </div>
      <PoweredBy />
    </Container>
  )
}

export default ControlPanel
